import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppStore } from './store/app.store';
import { AppRoutingModule } from './app-routing.module';
import { PersistenceService } from './shared/services/persistence.service';
import { AppComponent } from './app.component';
import { SystemUserService } from './shared/services/system-user.service';
import { MenuHoverModule } from './shared/components/menu-hover/menu-hover.module';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenModule } from './shared/token-module/token.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const maskConfigFunction: () => Partial<IConfig> = () => ({
  validation: false,
});

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MenuHoverModule,
    HttpClientModule,
    TokenModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppStore,
    SystemUserService,
    PersistenceService,
    { provide: MAT_DATE_LOCALE, useValue: 'he-HE' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'dd.MM.yyyy',
          monthYearLabel: 'dd.MM.yyyy',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'dd.MM.yyyy',
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
