import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAuthGuard } from './shared/services/checkAuth.guard';
import { EmployerGuard } from './shared/services/employer.guard';
import { WorkerGuard } from './shared/services/worker.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [CheckAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'worker',
    canActivate: [WorkerGuard],
    loadChildren: () => import('./worker/worker.module').then(m => m.WorkerModule)
  },
  {
    path: 'employer',
    canActivate: [EmployerGuard],
    loadChildren: () => import('./employer/employer.module').then(m => m.EmployerModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
