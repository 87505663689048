import { UserInterface } from "../shared/interfaces/user.interface";
import { HeaderInterface } from "../shared/interfaces/header.interaface";

export interface AppState {
  user: UserInterface | null;
  header: HeaderInterface;
  isExit: boolean;
  language: string;
}

export const DEFAULT_STATE: AppState = {
  user: {
    role: null,
    email: '',
    firstName: '',
    isNew: false,
    secondName: '',
    token: '',
    userId: '',
    profileFillingStep: 0,
    isProfileFilled: false
  },
  header: {
    headerTitle: '',
    breadcrumbs: []
  },
  language: 'English',
  isExit: false,
};

