import { Component } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-menu-hover',
  templateUrl: './menu-hover.component.html',
  styleUrls: ['./menu-hover.component.scss'],
})
export class MenuHoverComponent {
  timedOutCloser: any;

  constructor() { }

  mouseEnter(trigger: MatMenuTrigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger: MatMenuTrigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 60);
  }
}
