import {tap} from 'rxjs';
import {Component, OnInit} from '@angular/core';

import {AppStore} from './store/app.store';
import {PersistenceService} from './shared/services/persistence.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {PersistenceStorageKeysEnum} from './shared/interfaces/enums/persistence-storage-keys.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {SystemUserService} from './shared/services/system-user.service';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public statePage = 'default';
  public textDir: string = 'ltr';
  public userOS: string = 'unknown';

  constructor(
    private appStore: AppStore,
    private http: HttpClient,
    private route: ActivatedRoute,
    private persistenceService: PersistenceService,
    private translate: TranslateService,
    private _systemUserService: SystemUserService,
    private router: Router,
    private location: Location
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'he') {
        this.textDir = 'rtl';
      } else {
        this.textDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    this.subscribeSaveAnalyticsAndGoToAppStore()
    this._checkAuth();
    this.getLanguage();
    this.getOS()
    // FIXME: Refactoring this peace of code
    // this.getOS();
    // this.redirectionUser();

    this.router.events.subscribe(() => {
      const path = this.location.path().toString();
      // if page is "confirm email" -> show mobile view; else block UI
      if (
        path === '/public/terms' ||
        path.includes('email-verified') ||
        path.includes('change-forgotten-password')
      ) {
        this.statePage = 'default';
      } else {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          this.statePage = 'mobileMessage';
        }
      }
    });

    this.appStore.isExit$
      .pipe(
        tap((isExit) => {
          if (isExit) {
            this._systemUserService.logout();
            this.appStore.setIsExit(false);
          }
        })
      )
      .subscribe();
  }

  private getLanguage() {
    const lang = this.persistenceService.get(PersistenceStorageKeysEnum.LANG);
    if (lang) {
      if (lang === 'he') {
        this.translate.use('he');
      } else {
        this.translate.use('en');
      }
    }
  }

  private _checkAuth(): void {
    const currentUser = this.persistenceService.get('user');
    if (currentUser) {
      this.appStore.setUser(currentUser);
    } else {
      this.appStore.setIsExit(false);
    }
  }


  private subscribeSaveAnalyticsAndGoToAppStore(): void {
    this.route.queryParams.subscribe(param => {
      if (param['fromEmail'] && param['userId']) {
        this.http.post(`${environment.apiUrl}/employees/set-profile-fill-info`, {
          employeeId: param['userId'],
          emailToClick: true
        }).subscribe({
          next: (response) => {
            if (this.userOS === 'Android')
              window.location.href = "https://play.google.com/store/apps/details?id=com.gigzy.gigzyApp"
            else
              window.location.href = "https://apps.apple.com/ru/app/gigzy/id6443645741";
          },
          error: error => console.error(error)
        })
      }
    })
  }


  private getOS(): void {
    const userAgent: string = window.navigator.userAgent;
    const platform: string = window.navigator.platform;
    const macosPlatforms: string[] = [
      'Macintosh',
      'MacIntel',
      'MacPPC',
      'Mac68K',
    ];
    const windowsPlatforms: string[] = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms: string[] = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
      this.userOS = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      this.userOS = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      this.userOS = 'Windows';
    } else if (/Android/.test(userAgent)) {
      this.userOS = 'Android';
    } else if (/Linux/.test(platform)) {
      this.userOS = 'Linux';
    }
  }

  private redirectionUser(): void {
    const androidUrl: string =
      'intent://app.gigzy.net/#Intent;scheme=gigzy;package=com.gigzy.gigzyApp;end';
    const iOsUrl: string =
      'https://apps.apple.com/ru/app/gigzy/id6443645741?platform=iphone';

    if (this.userOS === 'Android') {
      window.location.replace(androidUrl);
    }
    if (this.userOS === 'iOS') {
      window.location.replace(iOsUrl);
    }
  }
}
