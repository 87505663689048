<div dir="{{ textDir }}" [class]="textDir" *ngIf="statePage === 'default'">
  <router-outlet></router-outlet>
</div>

<ng-container *ngIf="statePage === 'mobileMessage'">
  <div class="instructions-wrapper">
    <img
      class="forgot-password__icon"
      src="./assets/svg/logo.svg"
      alt="Gigzy logotype"
    />

    <div class="instructions__label forgot-password__label">
      <h1>This site is not supported</h1>

      <span>on mobile</span>
    </div>

    <div class="instructions__message">
      <span>Please login from a personal computer,</span>

      <span>or download our mobile application</span>
    </div>

    <div class="mobile-apps">
      <a href="https://apps.apple.com/ru/app/gigzy/id6443645741">
        <img
          class="store_svg"
          src="./assets/svg/app-store.svg"
          alt="Gigzy application on App Store"
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.gigzy.gigzyApp">
        <img
          class="store_svg"
          src="./assets/svg/google-store.svg"
          alt="Gigzy application on Google Store"
        />
      </a>
    </div>
  </div>
</ng-container>
