<div
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  (mouseenter)="mouseEnter(menuTrigger)"
  (mouseleave)="mouseLeave(menuTrigger)"
>
  <ng-content select="[trigger]"></ng-content>
</div>
<mat-menu #menu="matMenu" [hasBackdrop]="false" yPosition="below" xPosition="before">
  <div class="menu" (mouseenter)="mouseEnter(menuTrigger)" (mouseleave)="mouseLeave(menuTrigger)">
    <ng-content select="[content]"></ng-content>
  </div>
</mat-menu>
